const SPACE_DATA = {
		"u6981363":  [{space_js: "", space_type: "inters", space_id: ""}],
		"u6981365":  [{space_js: "", space_type: "native", space_id: ""}],
		"u6981364":  [{space_js: "", space_type: "native", space_id: ""}],
		"u6981366":  [{space_js: "", space_type: "native", space_id: ""}],
		"u6981367":  [{space_js: "", space_type: "native", space_id: ""}],
		"u6981368":  [{space_js: "", space_type: "inters", space_id: ""}],
		"u6981370":  [{space_js: "", space_type: "native", space_id: ""}],
		"u6981369":  [{space_js: "", space_type: "native", space_id: ""}],
		"u6981371":  [{space_js: "", space_type: "native", space_id: ""}],
		"u6981372":  [{space_js: "", space_type: "native", space_id: ""}],
};
export const SPACE_MAP = {
	Home_inter:  SPACE_DATA["u6981363"],
	Home_native:  SPACE_DATA["u6981365"],
	Home_banner:  SPACE_DATA["u6981364"],
	Detail_inter:  SPACE_DATA["u6981363"],
	Detail_native:  SPACE_DATA["u6981365"],
	Detail_banner:  SPACE_DATA["u6981364"],
	List_native:  SPACE_DATA["u6981365"],
	List_banner:  SPACE_DATA["u6981364"],
	Result_inter:  SPACE_DATA["u6981363"],
	Result_native:  SPACE_DATA["u6981365"],
	Result_banner:  SPACE_DATA["u6981364"],
	Result_native_center1:  SPACE_DATA["u6981366"],
	Result_native_center2:  SPACE_DATA["u6981367"],
}